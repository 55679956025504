$(function(){

    //キャラセレクト
    $("#chara_select").change(function(){
        change_text(find_search_ward(" + " , "" , ""))
    });

    //タグセレクト
    $('.tags').on('click', function(){
        if ($(this).hasClass('selected')) {
            $(this).removeClass('selected')
        } else {
            $(this).addClass('selected')
        }
        change_text(find_search_ward(" + " , "" , ""))
    });


    //検索

    $('#bilibili').on('click', function(){
        var url = url_on_device(
            "https://search.bilibili.com/all?keyword=" ,
            "https://m.bilibili.com/search?keyword=")
        var ward = find_search_ward("%20" , "" , "")
        page_transition(url , ward)
    });

    $('#google').on('click', function(){
        var url = "https://www.google.com/search?hl=cn&q="
        var ward = find_search_ward("+" , "" , "")
        page_transition(url , ward)
    });

    $('#youtube').on('click', function(){
        var url = "https://www.youtube.com/results?search_query="
        var ward = find_search_ward("+" , "" , "")
        page_transition(url , ward)
    });

    $('#buhitter').on('click', function(){
        var url = "https://buhitter.com/search?q="
        var ward = find_search_ward("+" , "" , "")
        ward = ward.replace( "+" + '图片' , "" )
        page_transition(url , ward)
    });

    $('#weibo').on('click', function(){
        var space = "%20"
        var ward = find_search_ward(space , "%23" , "%23")

        if(ward.match('图片')) {
            ward = ward.replace( space + '%23图片%23' , "" )
            var url = url_on_device(
                "https://s.weibo.com/pic?q=",
                "https://m.weibo.cn/search?containerid=100103type%3D1%26q%3D"
            )
        } else {
            var url = url_on_device(
                "https://s.weibo.com/weibo?q=",
                "https://m.weibo.cn/search?containerid=100103type%3D1%26q%3D"
            )
        }

        page_transition(url , ward)
    });

    //トップ動画紹介
    $('.recommend').on('click', function(){
        if ($(this).hasClass('searchlink')) {
            var url = url_on_device(
                "https://search.bilibili.com/all?keyword=",
                "https://m.bilibili.com/search?keyword=")
            var ward = $(this).attr('cname')
        }
        else {
            var url = url_on_device(
                "https://www.bilibili.com/video/",
                "https://m.bilibili.com/video/")
            var ward = $(this).attr('videoid')
        }

        page_transition(url , ward)
    });

    //リセット
    $('#reset').on('click', function(){
        reset_tags()
        $("#search_ward").text('明日方舟')
    });

    //セレクトボックス絞り
    $("#job_select").change(function(){
        pull_up_data()
    });
    $("#rare_select").change(function(){
        pull_up_data()
    });

    /*
        functions
    */

    //検索ワード
    function change_text(ward) {
        $("#search_ward").text(ward)
    }

    function find_search_ward(space , pre , post) {
        var ward =  pre + '明日方舟' + post

        var job   = $("#job_select   option:selected").val();
        var rare  = $("#rare_select  option:selected").val();
        var chara = $("#chara_select option:selected").val();

        if (chara.length != 0) {
            ward = ward + space + pre + chara + post
        }
        else {
            if (job.length != 0) {
                ward = ward + space + pre + job + post
            }
            if (rare.length != 0) {
                //ward = ward + space + pre + rare + post
            }
        }

        $('.tags').each(function() {
            if ($(this).hasClass('selected')) {
                ward = ward + space + pre + $(this).attr('cname') + post
            }
        });

        return ward
    }
    
    //検索　ページ遷移
    function page_transition(url , search_ward) {
        //window.location.href = url + search_ward;
        open( url + search_ward, "_blank" ) ;
    }

    //リセット
    function reset_tags() {
        $("#job_select").val("");
        $("#rare_select").val("");
        pull_up_data()

        $('.tags').each(function() {
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected')
            }
        });
    }
    
    //セレクトボックス絞り
    function pull_up_data() {
        var job  = $("#job_select  option:selected").val();
        var rare = $("#rare_select option:selected").val();

        $.get( "change_select_box" ,
            { job: job , rare: rare },
            function(data){
                change_text(find_search_ward(" + " , "" , ""))
            }
        );
    }

    //PCとスマホでurl切り替え
    function url_on_device(pc_url , m_url) {
        const ua = navigator.userAgent;
        if (ua.indexOf('iPhone') > -1 || (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1)) {
            var url = m_url
        }
        else {
            var url = pc_url
        }
        return url
    }

});